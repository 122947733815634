<template>
  <div class="plk_fileeditor">
    <Menu></Menu>
    <div class="content">
      <div v-if="content">
        <h5>
          <router-link class="gotohome" :to="{ name: editorPage }">{{$t('designer.fileeditor.home')}}</router-link> <i class="fas fa-angle-right"></i> {{filename}}
        </h5>
        <codemirror v-model="content2update" :options="cmOptions" />
        <span v-if="content.isJSON && errorCode" class="error">{{errorCode}}</span>
      </div>
      <div v-else>
        {{$t('designer.fileeditor.notfound')}}
        <button v-if="filename" @click="createFile()">{{$t('designer.fileeditor.create')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import i18n from './i18n'
import { APP_URL_FILENAME_PARAM } from '@/enviroment/app' // SI lo cambiamos debemos cambiarlo tambien en el watch
import { codemirror } from 'vue-codemirror'
import Menu from './components/menus/Menu'
import { VIEWS as VIEWSDESIGN } from '@/router/design'
import 'codemirror/lib/codemirror.js'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/elegant.css'
import { Store, DESIGN } from '@/store'

export default {
  name: 'fileeditor',
  components: {
    codemirror,
    Menu
  },
  data () {
    return {
      errorCode: '',
      filename: this.$route.query[APP_URL_FILENAME_PARAM],
      updatedView: false,
      content2update: undefined
    }
  },
  computed: {
    editorPage () {
      return VIEWSDESIGN.FILES.name
    },
    textMode () {
      if (this.content.isJSON) {
        return 'text/javascript'
      } else if (this.content.isJavascript) {
        return 'text/javascript'
      } else if (this.content.isCSS) {
        return 'text/css'
      }
      return 'text/html'
    },
    cmOptions () {
      return {
        tabSize: 2,
        autoCloseTags: true,
        mode: this.textMode,
        theme: 'elegant',
        lineNumbers: true,
        styleActiveLine: true,
        line: true,
        matchBrackets: true
      }
    },
    content () {
      if (this.filename && Store.getters[DESIGN.GETTERS.GET_FILE_CONTENT](this.filename)) {
        const content = Store.getters[DESIGN.GETTERS.GET_FILE_CONTENT](this.filename)
        Vue.set(this, 'content2update', content.data)
        return !content || content.isImage ? undefined : content
      } else {
        return undefined
      }
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
    },
    createFile () {
      Store.commit({
        type: DESIGN.MUTATIONS.CREATE_FILE,
        filename: this.filename,
        content: {
          data: '',
          base64Data: '',
          isImage: (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(this.filename),
          isHtml: (/\.(html)$/i).test(this.filename),
          isJavascript: (/\.(js)$/i).test(this.filename),
          isJSON: (/\.(json)$/i).test(this.filename),
          isPyhton: (/\.(py)$/i).test(this.filename),
          isCSS: (/\.(css|scss)$/i).test(this.filename)
        }
      })
    },
    evaluate2save (contentStr) {
      Vue.set(this, 'errorCode', '')
      let content2save = _.cloneDeep(this.content)
      content2save.data = contentStr
      content2save.base64Data = btoa(contentStr)
      if (this.content.isJSON) {
        try {
          let validateCode = JSON.parse(contentStr)
          content2save.data = JSON.stringify(validateCode, null, 2)
          content2save.base64Data = btoa(content2save.data)
        } catch (ex) {
          Vue.set(this, 'errorCode', this.$t('designer.fileeditor.malformedObject'))
        }
      }
      return content2save
    }
  },
  watch: {
    content2update () {
      let content = this.evaluate2save(this.content2update)
      Store.commit({
        type: DESIGN.MUTATIONS.UPDATE_FILE_CONTENT,
        filename: this.filename,
        content
      })
      this.$servicesPLK.purgeCache()
    },
    '$route.query.file' () {
      Vue.set(this, 'filename', this.$route.query[APP_URL_FILENAME_PARAM])
    }
  }
}
</script>
<style lang="scss">
  .plk_fileeditor {
    .CodeMirror {
      border: 1px solid #dee2e6;
      height: calc(93vh - 5.5rem);
    }
  }
</style>
<style scoped lang="scss">
  .plk_fileeditor {
    background: #f8f9fa;
    .error {
      color: #bb1414;
      text-align: right;
      display: block;
      font-size: small;
      font-weight: bold;
    }

    .gotohome {
      color: $color-base;
      cursor: pointer;
      text-decoration: none;
    }

    .colored {

    }

    .content {
      padding: 3vh;
      height: calc(100vh - 6rem);
      padding-left: calc(3vh + 50px);
    }
    .menu_components {
      padding: 5px;
      background: #848e98;
      height: 100vh;
      overflow: scroll;
      border-right: solid 1px #dee2e6;
    }
  }
</style>
